
import React, { useState } from 'react';

const Navbar = () => {
  const [expanded, setExpanded] = useState(false);

  const toggleNav = () => {
    setExpanded(!expanded);
  };

  return (
  <>
    <header id="header" className="header fixed-top d-flex align-items-center">
      <div className="container-fluid d-flex align-items-center justify-content-between">
        
        <img src="assets/img/lklogo.jpg" alt="Logo" className="img-fluid" style={{ maxWidth: '100px' }} />

       <button className="mobile-nav-toggle mobile-nav-show bi bi-list" type="button" onClick={toggleNav}>
  <span className="mobile-nav-toggle mobile-nav-hide d-none bi bi-x"></span>
</button>

        <div className={`mb-2 mt-2 navbar-expand-lg ${expanded ? 'd-block d-lg-none' : 'd-none d-lg-block'} `}>
          <ul className=" mt-2 mb-2 navbar navbar-nav lg-ml-0">
          <li className="mt-2 mb-2 mr-lg-3 nav-item"><a className="nav-link nav-link mr-3" href="#news">News</a></li>
                <li className="mt-2 mb-2 mr-lg-3 nav-item"><a className="nav-link mr-3" href="#about">Mission</a></li>
                <li className="mt-2 mb-2 mr-lg-3 nav-item"><a className="nav-link mr-3" href="#values">Values</a></li>
                <li className="mt-2 mb-2 mr-lg-3 nav-item"><a className="nav-link mr-3" href="#team">Team</a></li>
                <li className="mt-2 mb-2 mr-lg-3 nav-item"><a className="nav-link mr-3" href="#faq">FAQ</a></li>
                <li className="mt-2 mb-2 mr-lg-3 nav-item"><a className="nav-link mr-3" href="#involved">Get Involved</a></li>
              </ul>
			
			
          </div>

  <a className="mt-4 mb-1 btn-book-a-table " href="https://givebutter.com/tWHNo8">Donate</a>
    </div>
	
			     		
		  
      </header>
	  
	  		<div class= "container-fluid mt-5">

<div class="banner d-flex align-items-center justify-content-center">

<div class="overlay">	
<div class="text-center justify-content-center">
      <h1><b>Community members like you have eliminated over $10,000 in student lunch debt and counting! Join us in making a difference.</b> </h1>

      <div class="button-group">
        <a href="#involved" class="get-involved-btn">Get involved</a>
      </div>
	  	  </div>
</div>
    </div>
</div>
	  </>
     
 


	
	
    
  );
}








const Banner = () =>{


	
}

// Section components
const Hero = () => {
  return (
<section id="about" class=" card align-items-center section-bg">
<div class="why-us section-bg">
     <div class="container">
      <div class="row justify-content-between gy-5">
        <div class="col-lg-5 d-flex flex-column justify-content-center align-items-center align-items-lg-start text-center text-lg-start">
          <h2 class="offset-lg-1" style = {{color:'#dd5726'}}><b>Lunch is on us!</b></h2>
          <p class="offset-md-1" style={{fontSize:'20px'}}> Let's Ketchup is a nonprofit organization that works to ensure students and families have the support and resources they  need to flourish<b> in the classroom and beyond.</b> </p>   </div>
        <div class="col-lg-5 text-center text-lg-center">
          <img src="assets/img/lklogo.jpg" class="img-fluid" alt=""/>
        </div>
      </div>
    </div>
</div>
  </section>
  );
}

const Mission = () => { return(
<section id="news">

  <div class="row">
  <div class="container">
    <div class="offset-lg-1 mr-2 col-lg-10">
	<div>
     <div class='text-center'> <h2> In the News </h2> </div>
      <div class="card mb-3">
  <div class="row g-0">
    <div class="col-md-4">
      <img src="assets/img/DJ1.jpg" class="img-fluid rounded-start" alt="..."/>
    </div>
    <div class="col-md-8">
      <div class="card-body">
        <h5 class="card-title"><b>Daily Journal: Let's Ketchup donates $7K toward canceling lunch debt</b></h5>
        <p class="card-text">After getting off the ground this year, Let’s Ketchup has met its first big fundraising goal and donated $7,000 to cancel a portion of the school lunch debt in Bourbonnais Elementary District 53. <a href = "https://www.daily-journal.com/news/lets-ketchup-donates-7k-toward-canceling-lunch-debt/article_65f3d428-136a-11ee-b3e3-abdb78666312.html"> Read more </a></p>
      </div>
    </div>
  </div>
  </div>
  </div>
      <div class="card mb-3">
  <div class="row g-0">
    <div class="col-md-4">
      <img src="assets/img/DJ2.jpg"  class="img-fluid rounded-start" alt="..."/>
    </div>
    <div class="col-md-8">
      <div class="card-body">
        <h5 class="card-title"><b>Daily Journal:Let's Ketchup hosting fundraiser Saturday in Manteno to help end local lunch debt </b></h5>
		<p class="card-text"> From 11 a.m. to 2 p.m. Saturday at Whitmore ACE Hardware, 361 S. Locust St., Manteno, Let's Ketchup — a local nonprofit organization that works to ensure that all students have access to proper nutrition...<a href = "https://www.daily-journal.com/life/lets-ketchup-hosting-fundraiser-saturday-in-manteno-to-help-end-local-lunch-debt/article_e72d1664-2010-11ee-8f85-a374c35c94f9.html"> Read more </a></p>
      </div>
    </div>
  </div>
  </div>

      <div class="card mb-3">
  <div class="row g-0">
    <div class="col-md-4">
      <img src="assets/img/DJ3.jpg" class="img-fluid rounded-start" alt="..."/>
    </div>
    <div class="col-md-8">
      <div class="card-body">
        <h5 class="card-title">All caught up: Nonprofit cancels school lunch debt in Momence</h5>
        <p class="card-text">Let’s Ketchup, a local nonprofit aimed at canceling student lunch debt, presented a check Monday for $2,866.96 to Momence Community Unit School District 1, covering 100% of outstanding lunch fees owed to the district. <a href ="https://www.daily-journal.com/news/all-caught-up-nonprofit-cancels-school-lunch-debt-in-momence/article_9693a032-2be2-11ee-8027-6fd0f608c11b.html"> Read more </a></p>
      </div>
    </div>
  </div>
  </div>
</div>
</div>
	<div class="row justify-content-center">

    <div class="col-lg-5">
	
 <div class='text-center'><h2> Past Events </h2></div>
      <div id="photoCarousel" class="carousel slide h-39	" data-bs-ride="carousel">
          <div class="carousel-indicators">
        <button type="button" data-bs-target="#photoCarousel" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
        <button type="button" data-bs-target="#photoCarousel" data-bs-slide-to="1" aria-label="Slide 2"></button>
        <button type="button" data-bs-target="#photoCarousel" data-bs-slide-to="2" aria-label="Slide 3"></button>
        <button type="button" data-bs-target="#photoCarousel" data-bs-slide-to="3" aria-label="Slide 4"></button>
        <button type="button" data-bs-target="#photoCarousel" data-bs-slide-to="4" aria-label="Slide 5"></button>
        <button type="button" data-bs-target="#photoCarousel" data-bs-slide-to="5" aria-label="Slide 6"></button>
        <button type="button" data-bs-target="#photoCarousel" data-bs-slide-to="6" aria-label="Slide 7"></button>
      </div>

      
      <div class="carousel-inner h-40">


        <div class="carousel-item active">
          <img src="assets/img/reel/reel2.png" class="d-block w-100"  style={{borderRadius: '10px 10px 0 0'}}  alt="..." />
        </div>

        <div class="carousel-item">
          <img src="assets/img/reel/reel3.png" class="d-block w-100" style={{borderRadius: '10px 10px 0 0'}}  alt="..." />
        </div>

        <div class="carousel-item">
          <img src="assets/img/reel/reel4.png" class="d-block w-100" style={{borderRadius: '10px 10px 0 0'}} alt="..." />
        </div>

        <div class="carousel-item">
          <img src="assets/img/reel5.png" style={{borderRadius: '10px 10px 0 0'}}  class="d-block w-100" alt="..." />
        </div>

        <div class="carousel-item">
          <img src="assets/img/reel6.png" style={{borderRadius: '10px 10px 0 0'}} class="d-block w-100" alt="..." />
        </div>

        <div class="carousel-item">
          <img src="assets/img/reel/reel7.png" style={{borderRadius: '10px 10px 0 0'}} class="d-block w-100" alt="..." />
        </div>

        <div class="carousel-item">
          <img src="assets/img/reel/reel8.png" style={{borderRadius: '10px 10px 0 0'}}  class="d-block w-100" alt="..." />
        </div>

      </div>

      
      <button class="carousel-control-prev" type="button" data-bs-target="#photoCarousel" data-bs-slide="prev">
        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
        <span class="visually-hidden">Previous</span>
      </button>
      <button class="carousel-control-next" type="button" data-bs-target="#photoCarousel" data-bs-slide="next">
        <span class="carousel-control-next-icon" aria-hidden="true"></span>
        <span class="visually-hidden">Next</span>
      </button>


      </div>
    </div>
	</div>
</div>

</section>
);
}
const Values = () => {
  return (
   <section id="values" class="why-us section-bg">
   <div>
      <div class="container" data-aos="fade-up">
        <div class="row gy-4">

          <div class="col-lg-4" data-aos="fade-up" data-aos-delay="100">
            <div class="why-box">
              <h3>What we value</h3>
              <p>
               There are three core values that guide everything we do. We think it's important for potential donors to know what these values are and to ensure everyone is on the same page about how we intend to behave as an organization.
              </p>
            </div>
          </div>

          <div class="col-lg-8 d-flex align-items-center">
            <div class="row gy-4">

              <div class="col-xl-4" data-aos="fade-up" data-aos-delay="200">

                <div class="icon-box d-flex flex-column justify-content-center align-items-center">
                  <h3>Integrity </h3>
                  <p>Let’s Ketchup is committed to transparency and the highest ethical standards. We strive to ensure that every dollar raised is given directly to youth and families in need. </p>
                </div>
              </div>

              <div class="col-xl-4" data-aos="fade-up" data-aos-delay="300">
                <div class="icon-box d-flex flex-column justify-content-center align-items-center">
                            <h3>Empathy</h3>
                  <p>Let’s Ketchup is aware of our community’s needs and is driven by a bedrock of care and concern to address those needs.</p>
                </div>
              </div>

              <div class="col-xl-4" data-aos="fade-up" data-aos-delay="400">
                <div class="icon-box d-flex flex-column justify-content-center align-items-center">
                  <h3>Progress </h3>
                  <p>Let’s Ketchup is motivated to help shoulder our neighbor’s burden, so our community continues to progress towards a level playing field for all.</p>
                </div>
              </div>

            </div>
          </div>

        </div>

      </div>
	  </div>
    </section>
  );
} 

const Team = () => {
  return (
    <section id="team">
    <section id="team" class="chefs section-bg">
      <div class="container" data-aos="fade-up">

        <div class="section-header">
          <p><span>Board of Directors</span></p>

        </div>
        <div class="row gy-3">

          <div class="col-lg-4 col-md-6 d-flex align-items-stretch" data-aos="fade-up" data-aos-delay="100">
            <div class="chef-member">
              <div class="member-img">
                <img src="assets/img/Michael BW.jpg" class="img-fluid" alt=""/>
                <div class="social">
                  <a href="https://www.linkedin.com/in/michael-murphy-628871209/"><i class="bi bi-linkedin"></i></a>
                </div>
              </div>
              <div class="member-info">
                <h4>Mike Murphy</h4>
                <span>President</span>
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-md-6 d-flex align-items-stretch" data-aos="fade-up" data-aos-delay="300">
            <div class="chef-member">
              <div class="member-img">
                <img src="assets/img/John Adams BW.jpg" class="img-fluid" alt=""/>
                <div class="social">
                  <a href="https://www.linkedin.com/in/john-adams-741b7b218/"><i class="bi bi-linkedin"></i></a>
                </div>
              </div>
              <div class="member-info">
                <h4>John Adams</h4>
                <span>Vice President</span>
                <span> Treasurer</span>
              </div>
            </div>
          </div>

          <div class="col-lg-4 col-md-6 d-flex align-items-stretch" data-aos="fade-up" data-aos-delay="200">
            <div class="chef-member">
              <div class="member-img">
                <img src="assets/img/MAL BW.jpg" class="img-fluid" alt=""/>
                <div class="social">
                  <a href="https://www.linkedin.com/in/mallory-kowaleski-40b4b6211/"><i class="bi bi-linkedin"></i></a>
                </div>
              </div>
              <div class="member-info">
                <h4>Mal Murphy</h4>
                <span>Secretary</span>
              </div>
            </div>
          </div>

          <div class="col-lg-4 col-md-6 d-flex align-items-stretch" data-aos="fade-up" data-aos-delay="300">
            <div class="chef-member">
              <div class="member-img">
                <img src="assets/img/Josh BW.jpg" class="img-fluid" alt=""/>
                <div class="social">
					<a href="https://www.linkedin.com/in/joshua-derocco/"><i class="bi bi-linkedin"></i></a>
                </div>
              </div>
              <div class="member-info">
                <h4>Josh DeRocco</h4>
                <span>Director of Marketing</span>
              </div>
            </div>
          </div>
          
                    
                    <div class="col-lg-4 col-md-6 d-flex align-items-stretch boardfont" data-aos="fade-up" data-aos-delay="300">
            <div class="chef-member">
              <div class="member-img">
                <img src="assets/img/Chase BW.JPG" class="img-fluid" alt=""/>
                <div class="social">
                  <a href="https://www.linkedin.com/in/chase-zajc-ab76812a/"><i class="bi bi-linkedin"></i></a>
                </div>
              </div>
              <div class="member-info">
                <h4>Chase Zajc</h4>
                <span>Grant Coordinator</span>
              </div>
            </div>
          </div>
                    <div class="col-lg-4 col-md-6 d-flex align-items-stretch" data-aos="fade-up" data-aos-delay="300">
            <div class="chef-member">
              <div class="member-img">
                <img src="assets/img/John Mullen BW.jpg" class="img-fluid" alt=""/>
                <div class="social">
                  <a href="https://www.linkedin.com/in/john-mullen-19195a175/"><i class="bi bi-linkedin"></i></a>
                </div>
              </div>
              <div class="member-info">
                <h4>John Mullen</h4>
                <span>Business Development Director</span>
              </div>
            </div>
          </div>
                    <div class="col-lg-4 col-md-6 d-flex align-items-stretch" data-aos="fade-up" data-aos-delay="300">
            <div class="chef-member">
              <div class="member-img">
                <img src="assets/img/Tygue BW.jpg" class="img-fluid" alt=""/>
                <div class="social">
                </div>
              </div>
              <div class="member-info">
                <h4>Ty Harris</h4>
                <span>Community Outreach Director</span>
              </div>
            </div>
          </div>
		  
		    <div class="section-header">
          <p><span>Advisory Board</span></p>

        </div>

		       <div class="col-lg-4 col-md-6 d-flex align-items-stretch" data-aos="fade-up" data-aos-delay="300">
            <div class="chef-member">
              <div class="member-img">
                <img src="assets/img/Paul BW.jpg" class="img-fluid" alt=""/>
                <div class="social">
				<a href="https://www.linkedin.com/in/paul-adams-396a8b219/"><i class="bi bi-linkedin"></i></a>
                </div>
              </div>
              <div class="member-info">
                <h4>Paul Adams</h4>
                <span>Business Partnership Advisor</span>
              </div>
            </div>
          </div>
		        <div class="col-lg-4 col-md-6 d-flex align-items-stretch" data-aos="fade-up" data-aos-delay="300">
            <div class="chef-member">
              <div class="member-img">
                <img src="assets/img/Doug BW.jpg" class="img-fluid" alt=""/>
                <div class="social">
                </div>
              </div>
              <div class="member-info">
                <h4>Doug Jones</h4>
                <span>CRM Advisor</span>
              </div>
            </div>
          </div>
		  
		        <div class="col-lg-4 col-md-6 d-flex align-items-stretch" data-aos="fade-up" data-aos-delay="300">
            <div class="chef-member">
              <div class="member-img">
                <img src="assets/img/Joe E BW.jpg" class="img-fluid" alt=""/>
                <div class="social">
                </div>
              </div>
              <div class="member-info">
                <h4>Joe Ewers</h4>
                <span>Community Engagement Advisor</span>
              </div>
            </div>
          </div>
		  
		   
		        <div class="col-lg-4 col-md-6 d-flex align-items-stretch" data-aos="fade-up" data-aos-delay="300">
            <div class="chef-member">
              <div class="member-img">
                <img src="assets/img/Steve BW.jpg" class="img-fluid" alt=""/>
                <div class="social">
                </div>
              </div>
              <div class="member-info">
                <h4>Steve Wilder</h4>
                <span>Community Engagement Advisor</span>
              </div>
            </div>
          </div>

        </div>

      </div>
    </section>
    </section>
  );
}

const FAQ = () => {
return (
  <section id="faq" class="about">
    <div class="container" data-aos="fade-up">
      <div class="section-header">
        <p><span>Frequently Asked Questions</span></p>
      </div>
      <div class="row gy-4">
        <div class="col d-flex align-items-start pt-3" data-aos="fade-up" data-aos-delay="300">
          <img src="assets/img/lunchkids2.jpg" class="img-fluid" alt="" />
        </div>
        <div class="col-lg-6 d-flex align-items-center ps-4" data-aos="fade-up" data-aos-delay="300">
          <div class="content ps-0 ps-lg-5">
            <p><b>How big is the problem?</b></p>
            <p>In the Illinois 2021 school year, it was estimated that 90,000 youth were food insecure and did not qualify for assistance. Statewide, the average debt of those 90,000 youth is $171 per child. As a result, school districts can accumulate large debts. For example, the total lunch debt in BESD 53 exceeds $30,000. More information about the problem of school lunch debt is available <a href="https://educationdata.org/school-lunch-debt">here.</a></p>
<p><b>Do the kids go hungry if the debt isn't paid?</b></p>
<p>Schools are required to provide lunch to students regardless of debt (which is a good thing!). However, individual schools often end up footing the bill, which takes away from funds used to serve their primary function of educating kids. In short, the funds have to come from somewhere. Let's Ketchup aims to play a small part in alleviating the financial burden of families and schools, which ultimately benefits the students.</p>

<p><b>What about Free and Reduced Lunch programs?</b></p>
<p>Free lunch is available to families who make less than $36,075 a year and reduced-cost lunch is available to those who make less than $51,338 (based on a family of four). However, many families making more than $51,338 still have difficulty making ends meet, and these are the families whose debt we tackle first. We work with the school district to target specific debt to ensure the families with the highest need receive assistance first.</p>
            <p><b>How do I donate by check?</b></p>
            <p>Thanks for your interest in donating! You can mail a check to:</p>
            <p>Let's Ketchup
              <br /> 293 Belmont Ave.
              <br /> Bourbonnais, IL 60914
            </p>
            <p>Please make the check payable to Let's Ketchup.</p>
          </div>
        </div>
      </div>
    </div>
  </section>
);

}

const GetInvolved = () => {
  return (
<section id="involved" class="about">
  <div class="col-lg-12 d-flex align-items-center">
    <div class="container">
      <div class="section-header">
        <h3><span>How can I get involved?</span></h3>
      </div>
      <p>The easiest way to get involved is to donate. Following and sharing our content on social media is also a big help. If you're looking for other ways to assist, we'd love to hear from you! Reach out to us at <a href="mailto:team@letsketchup.org">team@letsketchup.org</a>.</p>
      <p>If you're a business owner and you'd like to discuss a potential donation or partnership, please take a minute to complete our <a href="https://form.jotform.com/231709001526043">business partnership form</a>.</p>
    </div>
  </div>
</section>


  );
}

const Contact = () => {
  return (
    <section id="contact">
     // contact content
    </section>
  );
}

// App
const App = () => {



  return (
    <div>
	  <Navbar />
	  <Banner />
	  <Mission />
      <Hero />
      <Values />
      <Team />
      <FAQ />
      <GetInvolved />
    </div>
  );
}



export default App;
